<script lang="ts" setup>
import SpecialistTab from '~/components/specialist/SpecialistTab.vue'

defineProps<{
  tabs: string[]
}>()

const emit = defineEmits<{
  (e: 'selected'): void
}>()

const activeTabIndex = ref(0)

defineExpose({ activeTabIndex })
</script>

<template>
  <div class="lg:max-w-[360px]">
    <div class="flex items-center rounded-xl bg-[#F3F3F3] p-2">
      <div class="flex w-full items-center">
        <SpecialistTab
          v-for="(x, index) in tabs"
          :key="index"
          :is-active="activeTabIndex === index"
          :title="x"
          @click.prevent="activeTabIndex = index; emit('selected')"
        />
      </div>
    </div>
    <div class="mt-3 flex flex-col">
      <ul>
        <slot />
      </ul>
    </div>
  </div>
</template>
